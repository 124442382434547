$color: #afedec;
$speed: 7;

.pyramid {
  width: 70px;
  height: 80px;
  position: relative;
  perspective: 300px;
  perspective-origin: 50% 40%;
  margin-left: 1rem;
  margin-bottom: .5rem;
  @media (max-width: 767px) {
    width: 40px;
    height: 50px;
    margin-top: 2rem;
  }
}

.side {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 70px solid #ccc;
  transform-origin: 50% 0%;
  animation: spin $speed+s infinite linear;
  @media (max-width: 767px) {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #ccc;
  }
}

.back {
  animation-delay: -($speed/2) + s;
}

.right {
  animation-delay: -($speed/4) + s;
}

.left {
  animation-delay: -($speed * 0.75) + s;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg) rotateX(30deg);
    border-bottom-color: lighten($color, 5%);
  }
  25% {
    transform: rotateY(90deg) rotateX(30deg);
    border-bottom-color: darken($color, 5%);
    opacity: 1;
  }
  25.1% {
    opacity: 0;
  }
  50% {
    transform: rotateY(180deg) rotateX(30deg);
    border-bottom-color: darken($color, 12%);
  }
  74.9% {
    opacity: 0;
  }
  75% {
    transform: rotateY(270deg) rotateX(30deg);
    border-bottom-color: darken($color, 15%);
    opacity: 1;
  }
  100% {
    transform: rotateY(360deg) rotateX(30deg);
    border-bottom-color: lighten($color, 5%);
  }
}

