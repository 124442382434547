.extra-links {
    margin-top: .25rem;
    a {
        color: #929292;
        font-weight: 400;
    }
    a:not(:last-child) {
        margin-right: .75rem;
    }
}

.section-title {
    color: $brand-color;
    @media screen and (min-width: 767px) {
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
}

.pub-title a {
    color: $text-color;
}

.pub {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
}