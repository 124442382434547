.title {
    font-size: 3.5rem;
    font-weight: 200;
    position: absolute;
    bottom: 2rem;
    left: 4rem;
    line-height: 1.1;
    @media (max-width: $on-large) {
        font-size: 2.5rem;
    }
    @media (max-width: $on-medium) {
        font-size: 1.8rem;
        bottom: 1.5rem;
        left: 2.5rem;
    }
}

strong {
    font-weight: 900;
}

.text-weight-normal {
    font-weight: 500;
}

.mobile-small {
    font-size: 1rem;
}

@media (min-width: 768px) {
    .mobile-small {
        font-size: 2rem;
    }
}

// .container {
//     background-color: #000;
//     color: $background-color;
// }

section, .position-relative {
    position: relative;
}

.mb-1 {
    margin-bottom: 1rem;
}


.initial-section {
    padding-top: calc(21vh - 110px);
    padding-left: 27%;
    padding-right: 27%;
    // padding-top: 30dvh;
    @media (max-width: 767px) {
        padding-top: 12vh;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.initial-text {
    margin-bottom: 5rem;
    @media (max-width: 767px) {
        margin-bottom: 1.5rem;
    }
}

canvas {
    position: fixed;
    bottom: 4rem;
    right: 0rem;
    z-index: -1;
    @media (min-width: 768px) {
        bottom: 8rem;
        right: 0;
        left: 1rem;
        margin: auto;
    }
}

.keyword-filter {
    white-space: pre-wrap !important;
    word-wrap: break-word;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.link-underline {
    text-decoration: underline dotted lightgray 2px;
    text-decoration-color: lightgray !important;
    text-underline-offset: 5px;
}

.intro .pub {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important;
}

.intro-title {
    color: $brand-color;
}

.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        width: 73px;
        border-bottom: 2px solid lightgray;
    }
}

.view-all {
    display: flex;
    justify-content: flex-end;
}

.fun-fact {
    margin-top: 10px;
}

.container-fluid > .row {
    @media (max-width: 767px) {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
    }
}
.intro-name {
    font-size: 1.5rem;
    font-weight: bold;
}

iframe.youtube {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
