@charset "utf-8";

// fonts
$base-font-family: 'Roboto', Roboto, system, system-ui, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
$mono-font-family: 'Inconsolata', monospace;

$base-font-size:   14px !default;
$base-font-size-mobile:   12.5px !default;
$base-font-weight: 300 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.8 !default;

// colors
$text-color:       #000000 !default;
$black:            #1f1f21 !default;
$background-color: #ffffff !default;
$brand-color:      #3798a5 !default;
$brand-color-light:#65d3e1 !default;

$grey-color:       #5b5b5b !default;
$grey-color-light: lighten($grey-color, 30%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// numbers
$spacing-unit:     40px !default;
$content-width:    1200px !default;

$on-palm:          600px !default;
$on-laptop:        1080px !default;

$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;

%center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// mixins
@mixin media-query($device) {
  @media screen and (scssax-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// import partials
@import 'bootstrap/dist/css/bootstrap.min.css';
@import
  "base",
  "nav",
  "background",
  "pyramid",
  "home",
  "pub",
  "proj",
  "design"
;



