.logo {
    position: fixed;
    top: 3rem;
    left: 3rem;
    z-index: 999;
    max-width: 60px;
}

.navbar {
    z-index: 999;
    .nav-link {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

@media screen and (min-width: 768px) {
    .navbar {
        width: 100%;
        display: flex;
        height: 93px;
        padding-bottom: 17px;
        align-items: flex-end;
        position: sticky;
        top: 0;
        justify-content: flex-end;
        background-color: rgba(255, 255, 255, 0.95);
    }

}

.dropdown-menu[data-bs-popper] {
    left: auto;
    right: 0;
}
.dropdown-menu {
    font-size: 14px;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: $brand-color-light;
}

.navbar-toggler {
    position: fixed;
    right: 17px;
    top: 25px;
    border: none;
    z-index: 999;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

@media (max-width: 991px) {
    .navbar-collapse {
        background-color: aliceblue;
        padding: 20px 0 10px;
        position: fixed;
        top: 0;
        width: 100%;
        .dropdown-menu {
            background-color: aliceblue;
            border: none;
            font-size: 13px;
            font-weight: 300;
            color: #222;
            padding: 0;
            margin: 0;
            padding-left: 1rem;
        }
    }
}