.image-wrapper {
    max-width: 100%;
    width: 300px;
    height: 300px;
    overflow: hidden;
    img {
        height: 300px;
        width: auto;
    }
    @media (max-width: 300px) {
        width: 100%;
        height: auto;
        img {
            width: 100%;
            height: auto;
        }
    }
}

video {
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
}